.main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100vw;
  padding: 0;
  position: relative;
}

.container {
  text-align: center;
  padding: 2rem;
  width: 95%;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.logo {
  height: auto;
  width: 128px;
  display: block;
  position: fixed;
  top: 1rem;
  left: 1rem;
  will-change: transform;
  z-index: 10;
}

.title {
  font-size: 2.5rem;
  background: linear-gradient(175deg, #47ffff, #010e81, #850909, #000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.button {
  padding: .5rem 1rem;
  margin: 1rem;
  width: 44vw;
  height: 100%;
  border-radius: .5rem;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  background: linear-gradient(#272730, #2d4052);
  cursor: pointer;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(#2d4052, #272730);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.button:hover {
  background: linear-gradient(#230272, #000);
  border: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(#2d4052, #272730);
  opacity: 0.65;
  will-change: background, opacity;
}

.discordIcon {
  width: 24px !important;
  height: 22px !important;
  object-fit: contain;
  object-position: center;
}

@media (max-width: 768px) {
  .logo {
    width: 96px;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: center;
  }

  .button {
    margin-right: 50vw;
  }

  .title {
    font-size: larger;
  }
}

@media (max-width: 376px) {
  .button {
    font-size: 1.2rem;
    padding: 0.4rem 0.8rem;
  }
}
